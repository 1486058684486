<template>
  <main>
    <div @click="$router.go(-1)" class="flex cursor-pointer items-center mb-10">
      <div class="p-2 border border-opacity-25 rounded-md border-blue-500">
        <svg
          class="w-5 h-5 fill-current text-blue-500"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.2541 4.24106C16.5522 4.53326 16.5793 4.99051 16.3354 5.31272L16.2541 5.40503L9.52658 12L16.2541 18.595C16.5522 18.8872 16.5793 19.3444 16.3354 19.6666L16.2541 19.7589C15.956 20.0511 15.4896 20.0777 15.161 19.8386L15.0668 19.7589L7.7459 12.582C7.44784 12.2898 7.42074 11.8325 7.66461 11.5103L7.7459 11.418L15.0668 4.24106C15.3947 3.91965 15.9262 3.91965 16.2541 4.24106Z"
          />
        </svg>
      </div>
      <div class="ml-2 text-sm text-black">Back</div>
    </div>
    <!-- <multiselect
      :taggable="false"
      v-model="current"
      :options="uploads"
      :close-on-select="true"
      :clear-on-select="false"
      @select="getVal"
      :preserve-search="false"
      label="name"
      placeholder="Select Upload Name or Batch ID"
      :custom-label="nameWithLang"
    >
      <template slot="singleLabel" slot-scope="{ option }"
        ><strong> NAME : {{ option.name ? option.name : "N/A" }}, </strong>
        <strong class="ml-2">BATCH ID: {{ option.BatchId }}</strong> Selected
      </template>
      <template slot="afterList">
        <div
          v-if="page < allPages"
          @click="getMoreData"
          class="text-xs text-blue-500 text-center py-4  cursor-pointer"
        >
          <div class=" flex items-center  justify-center ">
            <div>
              Load More
            </div>
            <div class="ml-4" v-if="loading">
              <svg class="spinner w-6 h-6" viewBox="0 0 50 50">
                <circle
                  class="path"
                  cx="20"
                  cy="20"
                  r="10"
                  fill="none"
                  stroke-width="5"
                ></circle>
              </svg>
            </div>
          </div>
        </div>
      </template>
    </multiselect> -->
    <div class=" h-100 overflow-y-auto">
      <input
        type="search"
        name="serch"
        placeholder="Search Name or Barch ID..."
        v-model="search"
        class="bg-white h-10 px-5 pr-10 w-full rounded-full text-sm"
      />
      <table class="shadow-lg bg-white w-full">
        <thead>
          <tr>
            <th
              class="
                bg-blue-100
                text-xs
                border
                whitespace-nowrap
                text-left
                px-8
                py-2
              "
            >
              Name
            </th>
            <th
              class="
                bg-blue-100
                text-xs
                border
                whitespace-nowrap
                text-left
                px-8
                py-2
              "
            >
              Batch Id
            </th>
            <th
              class="
                bg-blue-100
                text-xs
                border
                whitespace-nowrap
                text-left
                px-8
                py-2
              "
            >
              Date
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(file, index) in filterUploads"
            :key="index"
            @click="getVal(file.ids[0], file)"
            :class="index % 2 ? 'bg-gray-300' : ''"
          >
            <!-- <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.merchant_name }}
              </td> -->
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ file.batch_name ? file.batch_name : "N/A" }}
            </td>
            <td class="border px-8 py-3 max-w-sm whitespace-nowrap text-xs">
              {{ file.ids[0] }}
            </td>
            <td class="border px-8 py-3 max-w-sm whitespace-nowrap text-xs">
              {{
                moment(file.created_at).format("DD MMM YYYY - h:mm:ss a") ||
                  "N/A"
              }}
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="page < allPages"
        @click="getMoreData"
        class="text-xs text-blue-500 text-center py-4  cursor-pointer"
      >
        <div class=" flex items-center  justify-center ">
          <div class="ml-4" v-if="loading">
            <svg class="spinner w-6 h-6" viewBox="0 0 50 50">
              <circle
                class="path"
                cx="20"
                cy="20"
                r="10"
                fill="none"
                stroke-width="5"
              ></circle>
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div class="ml-4" v-if="loading">
      <svg class="spinner w-10 h-10" viewBox="0 0 50 50">
        <circle
          class="path"
          cx="20"
          cy="20"
          r="10"
          fill="none"
          stroke-width="5"
        ></circle>
      </svg>
    </div>
    <div class="mt-20" v-else-if="selected.length">
      <h1 v-if="info.batch_name">Name:{{ info.batch_name }}</h1>
      <div>
        <h1 v-if="info.ids.length">Batch Id: {{ info.ids[0] }}</h1>
      </div>
      <div class="overflow-scroll py-6">
        <table class="shadow-lg bg-white w-full">
          <thead>
            <tr>
              <th
                class="
                  bg-blue-100
                  text-xs
                  border
                  whitespace-nowrap
                  text-left
                  px-8
                  py-2
                "
              >
                Id
              </th>
              <th
                class="
                  bg-blue-100
                  text-xs
                  border
                  whitespace-nowrap
                  text-left
                  px-8
                  py-2
                "
              >
                Name
              </th>
              <th
                class="
                  bg-blue-100
                  text-xs
                  border
                  whitespace-nowrap
                  text-left
                  px-8
                  py-2
                "
              >
                Account Name
              </th>
              <th
                class="
                  bg-blue-100
                  text-xs
                  border
                  whitespace-nowrap
                  text-left
                  px-8
                  py-2
                "
              >
                Account Number
              </th>
              <th
                class="
                  bg-blue-100
                  text-xs
                  border
                  whitespace-nowrap
                  text-left
                  px-8
                  py-2
                "
              >
                Amount
              </th>
              <th
                class="
                  bg-blue-100
                  text-xs
                  border
                  whitespace-nowrap
                  text-left
                  px-8
                  py-2
                "
              >
                Bank Code
              </th>
              <th
                class="
                  bg-blue-100
                  text-xs
                  border
                  whitespace-nowrap
                  text-left
                  px-8
                  py-2
                "
              >
                Batch Transaction Reference
              </th>
              <th
                class="
                  bg-blue-100
                  text-xs
                  border
                  whitespace-nowrap
                  text-left
                  px-8
                  py-2
                "
              >
                Narration
              </th>
              <th
                class="
                  bg-blue-100
                  text-xs
                  border
                  whitespace-nowrap
                  text-left
                  px-8
                  py-2
                "
              >
                Transaction Reference
              </th>
              <th
                class="
                  bg-blue-100
                  text-xs
                  border
                  whitespace-nowrap
                  text-left
                  px-8
                  py-2
                "
              >
                Transaction Status
              </th>
              <th
                class="
                  bg-blue-100
                  text-xs
                  border
                  whitespace-nowrap
                  text-left
                  px-8
                  py-2
                "
              >
                Created At
              </th>
              <th
                class="
                  bg-blue-100
                  text-xs
                  border
                  whitespace-nowrap
                  text-left
                  px-8
                  py-2
                "
              >
                Updated At
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="hover:bg-gray-300"
              v-for="data in selected"
              :key="data._id"
            >
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ data._id }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ data.name }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ data.AccountName }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ data.AccountNumber }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ data.Amount }}
              </td>
              <td class="border px-8 py-3 max-w-sm whitespace-nowrap text-xs">
                <p>
                  {{ getBankName(data.BankCode) }}
                </p>
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ data.BatchTransactionReference }}
              </td>

              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ data.Narration }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ data.TransactionReference }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ data.TransactionStatus }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{
                  moment(data.created_at).format("DD MMM YYYY - h:mm:ss a") ||
                    "N/A"
                }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{
                  moment(data.updated_at).format("DD MMM YYYY - h:mm:ss a") ||
                    "N/A"
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Modal
      size="max-w-xl"
      v-if="bulkUploadModal"
      @close="bulkUploadModal = false"
    >
      <form @submit.prevent="uploadCsv">
        <div>
          <p>Enter Bulk Upload Name</p>
          <div>
            <input
              type="text"
              class=" border-2 pl-2 py-2 rounded-md mt-5  border-gray-400"
              required
              v-model="name"
            />
            <button
              type="submit"
              class="block mt-12 py-3 uppercase shadow bg-blue-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs  px-8 rounded"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </Modal>
  </main>
</template>

<script>
import Multiselect from "vue-multiselect";
import moment from "moment";

export default {
  data() {
    return {
      uploads: [],
      current: "",
      selected: [],
      info: null,
      moment,
      bulkUploadModal: false,
      page: 1,
      allPages: null,
      loading: false,
      search: "",
      banks: [],
    };
  },
  computed: {
    filterUploads() {
      return this.uploads.filter((upload) => {
        return [];

        // upload.name.toLowerCase().includes(this.search.toLowerCase()) ||
        //   upload.BatchId[0].toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  components: {
    Multiselect,
  },
  watch: {
    async bulkUploadModal(x) {
      if (x) {
        this.loading = true;
        await this.$store.dispatch("BULK_UPDATE_STATUS");
      }
    },
  },

  methods: {
    getBankName(code) {
      let k = this.banks.filter((bank) => bank["bank_code"] === code);
      if (k) {
        return k[0].name;
      }
    },
    async bulkUpload() {
      this.loading = true;
      let result = await this.$store.dispatch("BULK_UPLOAD", {
        page: this.page,
      });
      this.loading = false;

      this.uploads = result.data.data.items;
    },
    nameWithLang({ name, BatchId }) {
      return `[Name: ${name ? name : "N/A"}] [Batch ID: ${BatchId}]`;
    },
    async getVal(x, y) {
      // this.selected = x.Items;
      // this.info = x;
      // this.info = y;
      this.loading = true;
      this.info = y;
      let res = await this.$store.dispatch("BULK_STATUS", { ids: x });
      this.loading = false;
      this.selected = res.data;
    },
    async getMoreData() {
      this.page += 1;
      await this.bulkUpload();
    },
  },
  async mounted() {
    await this.bulkUpload();
    let res = await this.$store.dispatch("BANK_LIST");
    this.banks = res.data.items;
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.spinner {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: 50px;
  height: 50px;
}
.spinner .path {
  stroke: blue;
  stroke-linecap: round;
  -webkit-animation: dash 1.5s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite;
}

@-webkit-keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>
